
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddStockRegisterModal from "@/components/modals/forms/AddStockRegisterModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddStockRegisterModal,
    AddProductListSalesEnquiry,
    // MixedWidget7,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
    const searchTearm = ref("");

    interface WIJournal {
      active: boolean;
      product_batch_no: string;
      invoice_no: string;
      invoice_date: string;
      uom_id: number;
      opening_qunatity: string;
      rate: string;
      other_charges: string;
      cost_to_company: string;
      material_condition_id: number;
      stock_remark: string;
      company_name: string;
      branch_name: string;
      product_name: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 1,
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      //   getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    // var tableData = ref<Array<WIJournal>>([]);
    var tableData = ref([
      {
        name: "MetalXeperts",
        quantity: "4",
        rate: "400",
        value: "2400",
      },
      {
        name: "JSW",
        quantity: "10",
        rate: "200",
        value: "2000",
      },
      {
        name: "TataSteel",
        quantity: "5",
        rate: "200",
        value: "1000",
      },
      {
        name: "Vedanta",
        quantity: "8",
        rate: "400",
        value: "3200",
      },
    ]);

    const getCompanyList = async (data) => {
      //   loadingData.value = true;

      try {
        var values = {
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: 1000,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_STOCK_REGISTER_LISTING_DATA, values)
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));

            debugger;
            // data.data = data.data.map((item) => {
            //   let typeValue = type_data.value.filter((type) => {
            //     return (
            //       type.stock_product_type_id === item.stock_product_type_id
            //     );
            //   });
            //   item.stock_product_type = typeValue[0]?.stock_product_type;
            //   return item;
            // });
            tableData.value = [];
            const totalCount = data.count;
            // set pagination
            paginationData.value["total"] = totalCount;
            data = data.data;
            var pg =
              paginationData.value["activePage"] *
              paginationData.value["perPage"];
            paginationData.value["start"] =
              pg - paginationData.value["perPage"];
            paginationData.value["end"] =
              paginationData.value["start"] +
              paginationData.value["perPage"] * 1;

            paginationData.value["totPage"] = Math.ceil(
              totalCount / paginationData.value["perPage"]
            );

            paginationData.value["mmaxPageg"] =
              paginationData.value["totPage"] <
              paginationData.value["mmaxPageg"]
                ? paginationData.value["totPage"]
                : paginationData.value["mmaxPageg"];

            // var resultsM = ref<Array<WIJournal>>([]);
            // var status_label = "";
            // var status_color = "";
            // var active_label = "";
            // var active_color = "";

            // for (let j = 0; j < data.length; j++) {
            //   resultsM.value = Array({
            //     active: data[j]["active"],
            //     invoice_no: data[j]["invoice_no"],
            //     opening_qunatity: data[j]["opening_qunatity"],
            //     invoice_date: data[j]["invoice_date"],
            //     uom_id: data[j]["uom_id"],
            //     other_charges: data[j]["other_charges"],
            //     rate: data[j]["rate"],
            //     product_batch_no: data[j]["product_batch_no"],
            //     cost_to_company: data[j]["cost_to_company"],
            //     material_condition_id: data[j]["material_condition_id"],
            //     stock_remark: data[j]["stock_remark"],
            //     company_name: data[j]["company_name"],
            //     branch_name: data[j]["branch_name"],
            //     product_name: data[j]["product_name"],
            //   });
            //   console.log("resultsm:" + JSON.stringify(resultsM.value));
            //   tableData.value.splice(
            //     j,
            //     resultsM.value.length,
            //     ...resultsM.value
            //   );
            // }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      //   await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Godown Report", []);
      //   initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      search,
      searchItems,
      checkedCompany,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
    };
  },
});
